<template>
	<div v-if="selectedFormRequest" class="flex flex-col h-full w-full">
		<FormRequest :form-request-id="selectedFormRequest"></FormRequest>
	</div>
	<div v-else>{{ selectedFormRequest }}</div>
</template>

<script setup lang="ts">
	import { useRoute } from 'vue-router';
	import { onMounted, ref, watch } from 'vue';
	import FormRequest from '@modules/form/components/FormRequest.vue';

	const route = useRoute();
	const selectedFormRequest = ref();

	onMounted(() => {
		selectedFormRequest.value = route.params.form_request_id;
	});

	watch(
		() => route.params.form_request_id,
		(newFormRequestId) => {
			selectedFormRequest.value = newFormRequestId;
		},
	);
</script>
